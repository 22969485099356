<script lang="ts" setup>
const { t } = useT();

defineProps<{
	title: string;
	icon?: string;
	showAll?: boolean;
	showNavButtons?: boolean;
	sliderActiveSlide?: boolean;
	isLast?: boolean;
}>();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const emit = defineEmits(["viewAllAction", "prevSlide", "nextSlide"]);
</script>

<template>
	<div class="header">
		<div class="content-left">
			<NuxtIcon v-if="icon" :name="icon" class="icon" />
			<AText variant="tulsa" :modifiers="['bold']" :data-tid="`slider-title-${getTitle(title)}`">{{ title }}</AText>
			<slot name="content-left" />
		</div>
		<div class="content-right">
			<AButton
				v-if="showAll"
				variant="secondary"
				:data-tid="`slider-${getTitle(title)}-all`"
				@click="emit('viewAllAction')"
			>
				<AText :modifiers="['uppercase', 'bold']">{{ t("View all") }}</AText>
			</AButton>
			<AButtonGroup v-if="showNavButtons" class="navigation">
				<AButton
					:class="{ 'disable-button': !sliderActiveSlide }"
					variant="secondary"
					:data-tid="`slider-${getTitle(title)}-prev`"
					@click="emit('prevSlide')"
				>
					<NuxtIcon name="16/arrow-back" />
				</AButton>
				<AButton
					:class="{ 'disable-button': isLast }"
					variant="secondary"
					:data-tid="`slider-${getTitle(title)}-next`"
					@click="emit('nextSlide')"
				>
					<NuxtIcon name="16/arrow-right" />
				</AButton>
			</AButtonGroup>
			<slot name="content-right" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;
	min-height: 36px;
}

.content-left {
	display: flex;
	align-items: center;
	gap: 8px;

	.icon {
		font-size: 24px;
		color: var(--costarika);

		@include media-breakpoint-down(md) {
			font-size: 20px;
		}
	}
}

.tulsa {
	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}

.content-right {
	display: flex;
	gap: 8px;

	.secondary:hover {
		background-color: var(--cankaya) !important;
	}

	.navigation {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		.disable-button {
			cursor: not-allowed;
			&:deep(svg) {
				> * {
					opacity: 0.5;
				}
			}
		}
	}
}
</style>
